import React, { useEffect, useState } from "react";

export const GlobalContext = React.createContext();

export function GlobalProvider({ children }) {
  const [user, setUser] = useState("");

  const getAccessToken = () => {
    return user?.token ?? "";
  };

  useEffect(() => {
    if (sessionStorage.getItem("wuser")) {
      setUser(JSON.parse(sessionStorage.getItem("wuser")));
    }
    window.addEventListener("storage", () => {
      console.log(sessionStorage.getItem("wuser"));
      if (sessionStorage.getItem("wuser")) {
        setUser(JSON.parse(sessionStorage.getItem("wuser")));
      } else {
        setUser("");
      }
    });
  }, []);

  const globals = {
    user: user,
    setUser: setUser,
    getAccessToken: getAccessToken,
  };

  return (
    <GlobalContext.Provider value={globals}>{children}</GlobalContext.Provider>
  );
}
