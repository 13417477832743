/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { MobileOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { login_with_otp, request_for_resent_otp } from "./api.js";
import logo from "./asset/logo.png";
import { message } from "antd";
import { InputOTP } from "antd-input-otp";
import { GlobalContext } from "./GlobalContext.js";

function App() {
  const { user, setUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [office, setOffice] = useState("");
  const [searchParams] = useSearchParams();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const candidate = JSON.parse(sessionStorage.getItem("Candidate_values"));
  const candidate_id = JSON.parse(sessionStorage.getItem("candidate_id"));
  useEffect(() => {
    if (user?.token) {
      navigate("/onboarding");
    }
  }, [user]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const onFinish = (values) => {
    setloading(true);
    console.log(values.otp.join(""));
    var obj_arr = {
      candidate_id: candidate_id,
      otp: values.otp.join(""),
    };
    login_with_otp(obj_arr)
      .then((res) => {
        console.log(res);
        setUser(res?.data?.items);
        sessionStorage.setItem("wuser", JSON.stringify(res?.data?.items));
        setloading(false);
        message.success("Welcome back!");
        navigate("/onboarding");
      })
      .catch((err) => {
        setloading(false);
        message.error("Invalid credentials!");
      });
  };

  const otpchange = (e) => {
    console.log(e);
  };
  const resendOTP = () => {
    setloading(true);
    var obj_arr = {
      candidate_id: candidate_id.toString(),
      mobile_number: candidate.mobile_number,
    };
    request_for_resent_otp(obj_arr)
      .then((res) => {
        console.log(res);
        setMinutes(0);
        setSeconds(30);
        setloading(false);
        message.success(res.data.message);
      })
      .catch((err) => {
        setloading(false);
        message.error("Request Timeout!");
      });
  };
  return (
    <div className="back">
      <div className="div-center">
        <div className="content">
          <div className="text-center">
            <img src={logo} alt=""></img>
          </div>
          <h5 className="text-center text-white">
            Please enter the one time password to verify your account
          </h5>
          <p className="text-center text-white">
            A code has been sent to{" "}
            {candidate.mobile_number.replace(/.(?=.{4,}$)/g, "*")}
          </p>
          <Form
            name="normal_login"
            className="login-form mt-4"
            initialValues={{
              office_number: searchParams.get("office"),
              password: "",
              remember: false,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Please input OTP",
                },
              ]}
            >
              <InputOTP onChange={otpchange} inputType="tel" />
            </Form.Item>

            <Form.Item>
              <div className="countdown-text text-white float-left">
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Didn't receive code?</p>
                )}
              </div>

              <div className="login-form-forgot float-right">
                <Button
                  type="default"
                  disabled={seconds > 0 || minutes > 0}
                  //   style={{
                  //     color: seconds > 0 || minutes > 0 ? "#000" : "#fff",
                  //   }}
                  onClick={resendOTP}
                >
                  Resend OTP
                </Button>
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default App;
