import { useState, useContext, useEffect } from "react";
import TopHeader from "./Header";
import { Button, Form, Input, Layout, Spin, message } from "antd";
import { GlobalContext } from "./GlobalContext";
import { ChangePasswordaction } from "./api";
import { Content, Footer } from "antd/lib/layout/layout";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const { user } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);
  const onFinish = (values) => {
    setloading(true);
    ChangePasswordaction(values, user?.token)
      .then((res) => {
        navigate("/onboarding");
        setloading(false);
        message.success(res?.data?.message ?? "Password updated successfully");
      })
      .catch((err) => {
        setloading(false);
        message.error(err?.response?.data?.error ?? "Invalid credentials!");
      });
  };
  return (
    <Layout className="layout">
      <TopHeader />
      <Spin spinning={loading}>
        <Content
          className="container p-0"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          <div className="site-layout-content h-100">
            <Form
              style={{ maxWidth: "400px", margin: "auto" }}
              name="normal_login"
              className="login-form"
              initialValues={{
                old_password: "",
                password: "",
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Old Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Old Password"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="New Password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Spin>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        © 2023 Excess Telecom Wireless | Wireless and Approved Affordable
        Connectivity Program Provider
      </Footer>
    </Layout>
  );
}

export default ChangePassword;
