/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import TopHeader from "./Header";
import { Button, Form, Input, Layout, Spin, message } from "antd";
import { GlobalContext } from "./GlobalContext";
import { PasswordReset } from "./api";
import { Content, Footer } from "antd/lib/layout/layout";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Logout } from "./api";

function ResetPassword() {
  const { user, setUser } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  const onFinish = (values) => {
    setloading(true);
    var obj_arr = {
      password: values.password,
    };
    PasswordReset(obj_arr, user?.token)
      .then((res) => {
        setloading(false);
        // message.success(res?.data?.message ?? "Password updated successfully");
        Logout(user?.token)
          .then((res) => {
            sessionStorage.removeItem("wuser");
            setUser("");
          })
          .catch((err) => message.error("Something went wrong!"));
      })
      .catch((err) => {
        setloading(false);
        message.error(err?.response?.data?.error ?? "Invalid credentials!");
      });
  };
  return (
    <Layout className="layout">
      <TopHeader />
      <Spin spinning={loading}>
        <Content
          className="container p-0"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          <div className="site-layout-content h-100">
            <Form
              style={{ maxWidth: "400px", margin: "auto" }}
              name="normal_login"
              className="login-form"
              initialValues={{
                old_password: "",
                password: "",
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please input Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="New Password"
                />
              </Form.Item>
              <Form.Item
                name="password"
                dependencies={["old_password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>
      </Spin>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        © 2023 Excess Telecom Wireless | Wireless and Approved Affordable
        Connectivity Program Provider
      </Footer>
    </Layout>
  );
}

export default ResetPassword;
